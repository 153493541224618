import PropTypes from "prop-types";

import { Container, Section, getProperty } from "@gh/shared";

import { CarouselGallery } from "../common/carousel-gallery";

export const Gallery = ({ data }) => {
  const images = getProperty(data, "images") || [];

  return images.length > 0 ? (
    <Section noPaddingTop={true} align="center">
      <Container>
        <CarouselGallery images={images} />
      </Container>
    </Section>
  ) : null;
};

Gallery.defaultProps = {
  data: {},
};

Gallery.propTypes = {
  data: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.object.isRequired),
  }),
};

import { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@gh/shared";

import { useActivitiesNext } from "hooks";

import { Meta } from "./common/meta";

import { Intro } from "./activity/intro";
import { Facts } from "./activity/facts";
import { Video } from "./activity/video";
import { Gallery } from "./activity/gallery";
import { Grid } from "./activity/grid";
import { Next } from "./activity/next";

const Template = ({ data }) => {
  const hidden = getProperty(data, "markdownRemark.frontmatter.activity.hidden");
  const activityPath = getProperty(data, "markdownRemark.frontmatter.path");
  const nextActivity = useActivitiesNext(activityPath);

  const meta = getProperty(data, "markdownRemark.frontmatter.meta");
  const intro = {
    title: getProperty(data, "markdownRemark.frontmatter.title"),
    content: getProperty(data, "markdownRemark.frontmatter.activity.content"),
    highlight: getProperty(data, "markdownRemark.frontmatter.activity.highlight"),
  };

  const facts = {
    items: getProperty(data, "markdownRemark.frontmatter.activity.facts"),
  };

  const gallery = {
    images: getProperty(data, "markdownRemark.frontmatter.activity.gallery"),
  };
  const grid = getProperty(data, "markdownRemark.frontmatter.activity.grid");
  const video = {
    video: getProperty(data, "markdownRemark.frontmatter.activity.video"),
  };

  const next = {
    nextActivity,
  };

  return (
    <Fragment>
      <Meta data={meta} />
      <Intro data={intro} showBackLink={!hidden} />
      <Facts data={facts} />
      <Grid data={grid} />
      <Gallery data={gallery} />
      <Video data={video} />
      {!hidden && <Next data={next} />}
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title
        meta {
          title
          description
        }

        activity {
          hidden
          highlight
          categories
          image {
            childImageSharp {
              gatsbyImageData(width: 600, height: 400, layout: CONSTRAINED)
            }
          }
          content
          facts {
            label
            value
          }
          grid {
            texts
            images_portrait: images {
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  height: 400
                  layout: CONSTRAINED
                  transformOptions: { cropFocus: ENTROPY }
                )
              }
            }
            images_landscape: images {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  height: 400
                  layout: CONSTRAINED
                  transformOptions: { cropFocus: ENTROPY }
                )
              }
            }
          }
          gallery {
            childImageSharp {
              gatsbyImageData(width: 750, layout: CONSTRAINED)
            }
          }
          video {
            id
          }
        }
      }
    }
  }
`;

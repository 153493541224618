import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { COLOR_PRIMARY, getProperty, isObject, Text, ButtonFlat, Button } from "@gh/shared";

import { useMeta } from "../../hooks";

import * as styles from "./video-vimeo.module.scss";

const BASE_SETTINGS = {
  title: false,
  byline: false,
  portrait: false,
  background: false,
  playsinline: true,
  color: COLOR_PRIMARY.replace(/#/, ""),
};
const BASE_URL = `https://player.vimeo.com/video`;

const Cookiebot = {
  renew: () => {
    if (
      `Cookiebot` in window &&
      isObject(window.Cookiebot) &&
      `renew` in window.Cookiebot &&
      typeof window.Cookiebot.renew === "function"
    ) {
      window.Cookiebot.renew();
    }
  },
  isReady: () => `Cookiebot` in window,
};

export const VideoVimeo = ({ id, autoplay, muted, loop, className }) => {
  const meta = useMeta();
  const [isReady, setIsReady] = useState(false);
  const wrapperClassName = useMemo(() => [styles.iframe, className].join(" "), [className]);
  const src = useMemo(() => {
    const urlSearchParams = new URLSearchParams({
      ...BASE_SETTINGS,
      autoplay,
      muted,
      loop,
    });

    return `${BASE_URL}/${id}?${urlSearchParams.toString()}`;
  }, [id, autoplay, muted, loop]);

  const cookieText = getProperty(meta, "meta.cookies.video_text");
  const cookieButton = getProperty(meta, "meta.cookies.video_button");

  useEffect(() => {
    setIsReady(Cookiebot.isReady());
    const handler = () => setIsReady(true);

    window.addEventListener(`CookiebotOnLoad`, handler);
    return () => {
      window.removeEventListener(`CookiebotOnLoad`, handler);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      {isReady ? (
        <div className="cookieconsent-optout-marketing">
          <div className={styles.cookies}>
            <Text wrapper="div">{cookieText}</Text>
            <ButtonFlat onClick={() => Cookiebot.renew()}>{cookieButton}</ButtonFlat>
          </div>
        </div>
      ) : null}
      <iframe
        className={wrapperClassName}
        data-cookieconsent="statistics"
        data-cookieblock-src={src}
      />
    </div>
  );
};

VideoVimeo.defaultProps = {
  id: null,
  loop: false,
  muted: false,
  autoplay: false,
  className: "",
};

VideoVimeo.propTypes = {
  id: PropTypes.string.isRequired,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  autoplay: PropTypes.bool,
  className: PropTypes.string,
};

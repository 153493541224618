import PropTypes from "prop-types";

import {
  LinkButtonArrowBack,
  Section,
  getProperty,
  TipIconDE,
  TipIconEN,
  useLocale,
} from "@gh/shared";

import { useMeta } from "hooks";

import { IntroTeaser } from "../common/intro-teaser";

import * as styles from "./intro.module.scss";

const ICON = {
  de: <TipIconDE className={styles.icon} />,
  en: <TipIconEN className={styles.icon} />,
};

export const Intro = ({ data, showBackLink }) => {
  const meta = useMeta();
  const { locale } = useLocale();

  const title = getProperty(data, "title");
  const content = getProperty(data, "content");
  const highlight = getProperty(data, "highlight");
  const labelBackOverview = getProperty(meta, "meta.navigation.label_back_overview");

  return (
    <Section align="center">
      <IntroTeaser
        title={title}
        content={content}
        teaser={
          showBackLink || highlight ? (
            <div className={styles.wrapper}>
              {showBackLink && (
                <LinkButtonArrowBack to="..">{labelBackOverview}</LinkButtonArrowBack>
              )}
              {highlight && <div className={styles.badge}>{ICON[locale]}</div>}
            </div>
          ) : null
        }
      />
    </Section>
  );
};

Intro.defaultProps = {
  data: {},
  showBackLink: true,
};

Intro.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    highlight: PropTypes.bool.isRequired,
  }).isRequired,
  showBackLink: PropTypes.bool.isRequired,
};

import PropTypes from "prop-types";

import { Container, Section, getProperty } from "@gh/shared";

import { VideoVimeo } from "../common/video-vimeo";

export const Video = ({ data }) => {
  const id = getProperty(data, "video.id");

  return typeof id === "string" ? (
    <Section noPaddingTop={true} align="center">
      <Container>
        <VideoVimeo id={id} />
      </Container>
    </Section>
  ) : null;
};

Video.defaultProps = {
  data: {},
};

Video.propTypes = {
  data: PropTypes.shape({
    video: PropTypes.object,
  }),
};

import PropTypes from "prop-types";

import { Title, Text, Section, Container, getProperty } from "@gh/shared";

import { useMeta } from "hooks";

import * as styles from "./facts.module.scss";

export const Facts = ({ data }) => {
  const meta = useMeta();

  const facts = getProperty(data, "items") || [];
  const labelFacts = getProperty(meta, "meta.activities.label_facts");

  return facts.length > 0 ? (
    <Section noPaddingTop={true} align="center">
      <Container>
        <Title size="s4" family="f2" weight="w4" transform="uppercase" className={styles.title}>
          {labelFacts}
        </Title>
        <div className={styles.items}>
          {facts.map((fact, index) => {
            const label = getProperty(fact, "label");
            const value = getProperty(fact, "value");

            return (
              <Text key={index} className={styles.item}>
                <span className={styles.label}>{label}</span>
                <span className={styles.value}>{value}</span>
              </Text>
            );
          })}
        </div>
      </Container>
    </Section>
  ) : null;
};

Facts.defaultProps = {
  data: {},
};

Facts.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

import { useMemo } from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import { Container, Section, Text, Markdown, getProperty } from "@gh/shared";

import * as styles from "./grid.module.scss";

const TYPE_TEXT = "text";
const TYPE_IMAGE_PORTRAIT = "portrait";
const TYPE_IMAGE_LANDSCAPE = "landscape";

const renderItem = ({ type, data }, index) => (
  <div key={index}>
    {type === TYPE_TEXT ? (
      <Text>
        <Markdown content={data} />
      </Text>
    ) : (
      <GatsbyImage alt="" image={getProperty(data, "childImageSharp.gatsbyImageData")} />
    )}
  </div>
);

export const Grid = ({ data }) => {
  const texts = getProperty(data, "texts") || [];
  const imagesPortrait = getProperty(data, "images_portrait") || [];
  const imagesLandscape = getProperty(data, "images_landscape") || [];

  const items = useMemo(() => {
    let i = 0;
    let even = true;
    const left = [];
    const right = [];
    const copyTexts = [...texts];
    const copyImagesPortrait = [...imagesPortrait];
    const copyImagesLandscape = [...imagesLandscape];
    const total = texts.length + imagesLandscape.length;

    let item;
    while (i < total) {
      switch (i % 4) {
        case 0: {
          const currentPortrait = copyImagesPortrait.shift();
          const currentLandscape = copyImagesLandscape.shift();

          const data = even ? currentPortrait : currentLandscape;
          const type = even ? TYPE_IMAGE_PORTRAIT : TYPE_IMAGE_LANDSCAPE;
          item = { data, type };
          break;
        }
        case 1: {
          const currentPortrait = copyImagesPortrait.shift();
          const currentLandscape = copyImagesLandscape.shift();

          const data = even ? currentLandscape : currentPortrait;
          const type = even ? TYPE_IMAGE_LANDSCAPE : TYPE_IMAGE_PORTRAIT;
          item = { data, type };
          break;
        }
        case 2:
        case 3: {
          const data = copyTexts.shift();
          const type = TYPE_TEXT;
          item = { data, type };
          break;
        }
      }

      if (i % 4 === 3) {
        even = !even;
      }

      const container = i % 2 === 0 ? left : right;
      container.push(item);
      i++;
    }

    return { left, right, total };
  }, [texts, imagesLandscape, imagesPortrait]);

  return items.total > 0 ? (
    <Section noPaddingTop={true} align="center">
      <Container>
        <div className={styles.grid}>
          <div className={styles.left}>{items.left.map(renderItem)}</div>
          <div className={styles.right}>{items.right.map(renderItem)}</div>
        </div>
      </Container>
    </Section>
  ) : null;
};

Grid.defaultProps = {
  data: {},
};

Grid.propTypes = {
  data: PropTypes.shape({
    texts: PropTypes.arrayOf(PropTypes.string.isRequired),
    images_portrait: PropTypes.arrayOf(PropTypes.object.isRequired),
    images_landscape: PropTypes.arrayOf(PropTypes.object.isRequired),
  }),
};

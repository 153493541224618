import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { Container, Section, LinkButtonArrow, getProperty } from "@gh/shared";

import { useMeta } from "hooks";

export const Next = ({ data }) => {
  const meta = useMeta();

  const pathNext = getProperty(data, "nextActivity.path");
  const labelNext = getProperty(meta, "meta.activities.label_next");

  return pathNext ? (
    <Section noPaddingTop={true} align="center">
      <Container>
        <LinkButtonArrow to={pathNext}>{labelNext}</LinkButtonArrow>
        <Helmet>
          <link rel="next" href={pathNext} />
        </Helmet>
      </Container>
    </Section>
  ) : null;
};

Next.defaultProps = {
  data: {},
};

Next.propTypes = {
  data: PropTypes.shape({
    nextActivity: PropTypes.object.isRequired,
  }).isRequired,
};
